import React from "react";
import { graphql } from "gatsby";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers";
import Layout from "../components/common/layout";
import GovernmentNavbar from "../components/government/government-navbar";
import PotencyList from "../components/government/potency-list";

export const query = graphql`
  query VillagePotentialPageQuery {
    potentials: allSanityVillagePotential(
      filter: {
        title: { ne: "null" }
        slug: { current: { ne: "null" } }
        _rawPreview: { ne: "null" }
      }
    ) {
      edges {
        node {
          id
          title
          _rawPreview
          slug {
            current
          }
        }
      }
    }
  }
`;

const VillagePotentialPage = (props) => {
  const { data } = props;

  const nodes = (data || {}).potentials
    ? mapEdgesToNodes(data.potentials).filter(filterOutDocsWithoutSlugs)
    : [];

  return (
    <Layout>
      <GovernmentNavbar />
      {nodes && <PotencyList nodes={nodes} />}
    </Layout>
  );
};

export default VillagePotentialPage;
